const PrivacyCR = () => {
    return (
        <>
            <h3>POLÍTICA DE PRIVACIDAD - GETBEAUTYFULL</h3>
            <p>
                GetBeautyFull, nuestro programa de lealtad de Cosméticos y Fragancias de SIMAN, MAC COSMETICS Y XCLAIM de la región. Acumula puntos por todas sus compras en Cosméticos y Fragancias de SIMAN, MAC COSMETICS y XCLAIM, los cuales los podrás redimir por variedad de premios, productos de belleza de tus marcas favoritas, servicios y cupones de descuentos. Sea parte de nuestra comunidad y únase a nosotros.
                Al registrarse como usuario de nuestro programa de Lealtad o acceder a nuestro servicio, acepta libre y expresamente que la información personal brindada por usted puede ser consultada y almacenada por GetBeautyFull, Almacenes Siman, MAC Cosmetics, Tiendas Xclaim u otra cadena o marca bajo la administración de las antes mencionadas.
                <br/><br/>
                Conocemos la importancia de mantener su información confidencial, tanto para ustedes como para nosotros. Si en cualquier momento desea que borremos su información de nuestros registros o bases de datos, puede comunicarse con nosotros al correo info@getbeautyfull.com o a cualquiera de nuestros medios de contacto, detallados en la siguiente dirección: <a href="https://sv.siman.com/contactenos">https://sv.siman.com/contactenos</a>.
                Según nuestros términos legales, GetBeautyFull se compromete a mantener la privacidad de toda la información proporcionada por un usuario del sitio de Internet y App, solamente revelarla si fuese requerida por una autoridad judicial competente en los países de El Salvador, Guatemala, Nicaragua y Costa Rica, donde operan nuestras cadenas.
                <br/><br/>
                En GetBeautyFull valoramos y respetamos tu privacidad. Esta Política de Privacidad explica cómo recopilamos, utilizamos y protegemos la información personal que nos proporcionas al participar en nuestro programa de lealtad de Cosméticos de SIMAN, MAC Cosmetics y Xlciam. Al participar en nuestro programa, aceptas los términos y condiciones descritos en esta política.
                <br/><br/>
                <ol>
                    <li>
                        Información recopilada:
                        <p>Seleccionamos la siguiente información personal cuando te registras en nuestro programa de lealtad:</p>
                        <ul>
                            <li>Nombre completo</li>
                            <li>Dirección de correo electrónico</li>
                            <li>Número de teléfono</li>
                            <li>Fecha de nacimiento</li>
                            <li>Ubicación</li>
                        </ul>
                        <p>Además, podemos recopilar información adicional relacionada de sus compras en los establecimientos físicos y/o sitios de web de nuestras cadenas mencionadas, las cuales son necesarias para la acumulación de sus puntos, registrados a través de su documento de identidad nacional. En ningún momento GetBeautyFull le solicitará a usted datos financieros como tarjetas de crédito o cuentas bancarias. La información de sus compras se encuentra en los registros internos de las cadenas y únicamente los montos de compras totales son informados a GetBeautyFull, una vez confirmada su afiliación, con el fin de acumular puntos para próximas redenciones de premios.</p>
                    </li>
                    <li>
                        Uso de la información:
                        <p>Utilizamos la información recopilada para los siguientes fines:</p>
                        <ul>
                            <li>Administrar su participación en nuestro programa de lealtad.</li>
                            <li>Personalizar su experiencia de redención y ofrecerle descuentos, promociones y ofertas relevantes, con su consentimiento previo, dado al momento de la afiliación.</li>
                            <li>Enviar comunicaciones relacionadas con el programa, como actualizaciones, cambios en los términos y condiciones, etc.</li>
                            <li>Mejorar nuestros premios y servicios, basándonos en sus preferencias y comentarios.</li>
                            <li>Realizar análisis y estudios de mercado para comprender mejor las necesidades y preferencias de nuestros clientes.</li>
                        </ul>
                    </li>
                    <li>
                        Compartir información:
                        <p>Importante aclarar, que No compartimos su información personal con terceros sin su consentimiento previo. Al menos que alguna autoridad del Estado lo requiera.</p>
                    </li>
                    <li>
                        Seguridad de la información:
                        <p>Implementamos medidas de seguridad técnicas y organizativas adecuadas para proteger tu información personal contra el acceso no autorizado, pérdida, uso indebido o divulgación de la misma.</p>
                    </li>
                    <li>
                        Sus derechos:
                        <p>Tiene derecho a acceder, corregir o eliminar la información personal que hemos recopilado sobre usted. Si desea ejercer alguno de estos derechos, contáctanos a través de los canales proporcionados al inicio de esta política. En el momento que desea desafiliarse de nuestro programa de Lealtad, puede realizarlo a través de nuestra App en la opción “Deseo desafiliarme”.</p>
                    </li>
                    <li>
                        Cambios en la política de privacidad:
                        <p>
                            Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Cualquier cambio será efectivo cuando se publique una versión actualizada en nuestra App y sitio web. Le recomendamos revisar periódicamente esta política para estar al tanto de cualquier actualización.
                            <br/><br/>
                            Si tienes alguna pregunta o inquietud sobre nuestra Política de Privacidad o el manejo de tu información personal, no dude en contactarnos en las direcciones proporcionadas anteriormente.
                            <br/><br/>
                            Fecha de entrada en vigencia: 20 de octubre 2023
                            <br/><br/>
                            Gracias por ser parte de GetBeautyFull, nuestro programa de lealtad de Cosméticos y Fragancias de Almacenes SIMAN, MAC Cosmetics y Xclaim.
                            <br/><br/>
                            Almacenes SIMAN S.A. de C.V.
                            <br/>
                            Sociedad jurídica constituida bajo las leyes de la República de El Salvador, con Número de Registro 301-8 y Número de Identificación Tributaria 0614-170266-001-3. Con sede en oficina corporativa, ubicada en Centro Comercial Galerías, Paseo General y Colonia Escalón, #3700, Estacionamiento 1, San Salvador, El Salvador, C.A. Teléfono (+503) 2250-2457.
                        </p>
                    </li>
                </ol>
            </p>
        </>
    );
}

export default PrivacyCR;