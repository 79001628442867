import brand_GT1 from '../../../assets/brands/gt/01-Marca.svg';
import brand_GT2 from '../../../assets/brands/gt/02-Marca.svg';
import brand_GT3 from '../../../assets/brands/gt/03-Marca.svg';
import brand_GT4 from '../../../assets/brands/gt/04-Marca.svg';
import brand_GT5 from '../../../assets/brands/gt/05-Marca.svg';
import brand_GT6 from '../../../assets/brands/gt/06-Marca.svg';
import brand_GT7 from '../../../assets/brands/gt/07-Marca.svg';
import brand_GT8 from '../../../assets/brands/gt/08-Marca.svg';
import brand_GT9 from '../../../assets/brands/gt/09-Marca.svg';
import brand_GT10 from '../../../assets/brands/gt/10-Marca.svg';
import brand_GT11 from '../../../assets/brands/gt/11-Marca.svg';
import brand_GT12 from '../../../assets/brands/gt/12-Marca.svg';
import brand_GT13 from '../../../assets/brands/gt/13-Marca.svg';
import brand_GT14 from '../../../assets/brands/gt/14-Marca.svg';
import brand_GT15 from '../../../assets/brands/gt/15-Marca.svg';
import brand_GT16 from '../../../assets/brands/gt/16-Marca.svg';
import brand_GT17 from '../../../assets/brands/gt/17-Marca.svg';

export const GT_BRANDS = [
    brand_GT1, brand_GT2, brand_GT3,
    brand_GT4, brand_GT5, brand_GT6,
    brand_GT7, brand_GT8, brand_GT9,
    brand_GT10, brand_GT11, brand_GT12,
    brand_GT13, brand_GT14, brand_GT15,
    brand_GT16, brand_GT17
];
