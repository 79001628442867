import { SV_BRANDS } from './brands/sv';
import { GT_BRANDS } from './brands/gt';
import { NI_BRANDS } from './brands/ni';
import { CR_BRANDS } from './brands/cr';

export const brandList = {
    sv: [...SV_BRANDS],
    gt: [...GT_BRANDS],
    cr: [...CR_BRANDS],
    ni: [...NI_BRANDS],
}