import brand_CR1 from '../../../assets/brands/cr/01-Marca.svg';
import brand_CR2 from '../../../assets/brands/cr/02-Marca.svg';
import brand_CR3 from '../../../assets/brands/cr/03-Marca.svg';
import brand_CR4 from '../../../assets/brands/cr/04-Marca.svg';
import brand_CR5 from '../../../assets/brands/cr/05-Marca.svg'
import brand_CR6 from '../../../assets/brands/cr/06-Marca.svg';
import brand_CR7 from '../../../assets/brands/cr/07-Marca.svg';
import brand_CR8 from '../../../assets/brands/cr/08-Marca.svg';
import brand_CR9 from '../../../assets/brands/cr/09-Marca.svg';
import brand_CR10 from '../../../assets/brands/cr/10-Marca.svg';
import brand_CR11 from '../../../assets/brands/cr/11-Marca.svg';
import brand_CR12 from '../../../assets/brands/cr/12-Marca.svg';
import brand_CR13 from '../../../assets/brands/cr/13-Marca.svg';
import brand_CR16 from '../../../assets/brands/cr/16-Marca.svg';
import brand_CR17 from '../../../assets/brands/cr/17-Marca.svg';
import brand_CR18 from '../../../assets/brands/cr/18-Marca.svg';
import brand_CR19 from '../../../assets/brands/cr/19-Marca.svg';
import brand_CR20 from '../../../assets/brands/cr/20-Marca.svg';
import brand_CR21 from '../../../assets/brands/cr/21-Marca.svg';
import brand_CR22 from '../../../assets/brands/cr/22-Marca.svg';
import brand_CR23 from '../../../assets/brands/cr/23-Marca.svg';
import brand_CR24 from '../../../assets/brands/cr/24-Marca.svg';
import brand_CR25 from '../../../assets/brands/cr/25-Marca.svg';
import brand_CR26 from '../../../assets/brands/cr/26-Marca.svg';
import brand_CR27 from '../../../assets/brands/cr/27-Marca.svg';
import brand_CR28 from '../../../assets/brands/cr/28-Marca.svg';
import brand_CR29 from '../../../assets/brands/cr/29-Marca.svg';
import brand_CR30 from '../../../assets/brands/cr/30-Marca.svg';
import brand_CR31 from '../../../assets/brands/cr/31-Marca.svg';
import brand_CR32 from '../../../assets/brands/cr/32-Marca.svg';

export const CR_BRANDS = [
    brand_CR1, brand_CR2, brand_CR3, brand_CR4, 
    brand_CR5, brand_CR6, brand_CR7, brand_CR8,
    brand_CR9, brand_CR10, brand_CR11, brand_CR12,
    brand_CR13, brand_CR16, brand_CR17, brand_CR18,
    brand_CR19, brand_CR20, brand_CR21, brand_CR22,
    brand_CR23, brand_CR24, brand_CR25, brand_CR26,
    brand_CR27, brand_CR28, brand_CR29, brand_CR30,
    brand_CR31, brand_CR32
]