import { useEffect, useState } from "react";
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import { RegionContext  } from './context/region';

import HomePage from "./pages/Home";
//import ComingSoonPage from "./pages/ComingSoon";
import ConditionsPage from "./pages/ConditionsPage";
import PrivacyPage from "./pages/PrivacyPage";
import CountrySelectorPage from "./pages/CountrySelectorPage";
import BrandsPage from "./pages/BrandsPage";
import Layout from "./layout";
import SucursalesPage from "./pages/SucursalesPage";

const Router = () => {
    const [region, setRegion] = useState(null);

    const router = createBrowserRouter([
        {
            path: '/',
            element: (<Layout><CountrySelectorPage/></Layout>)
        },
        {
            path: '/:country',
            element: (<Layout><HomePage/></Layout>)
        },
        {
            path: "/:country/terminos-y-condiciones",
            element: (<Layout><ConditionsPage/></Layout>)
        },
        {
            path: "/:country/politica-de-privacidad",
            element: (<Layout><PrivacyPage/></Layout>
            )
        },
        {
            path: "/:country/marcas",
            element: (<Layout><BrandsPage/></Layout>)
        },
        {
            path: "/:country/sucursales",
            element: (<Layout><SucursalesPage/></Layout>)
        }
    ]);

    useEffect(() => {
        const currentCountry = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const country = (country) => {
            switch (country) {
                case 'America/Managua':
                    return 'ni';
                case 'America/Costa_Rica':
                    return 'cr';
                case 'America/Guatemala':
                    return 'gt';
                case 'America/El_Salvador':
                    return 'sv';
                default:
                    return null;
            }
        }
        const currentRegion = country(currentCountry);
        if (currentRegion) setRegion(currentRegion);
    }, [])

    return (
        <RegionContext.Provider value={{region}}>
            <RouterProvider router={router}/>
        </RegionContext.Provider>
    );
}

export default Router;