import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import heroImage from '../../assets/hero.gif';
import appStore from '../../assets/app-store.svg';
import googlePlay from '../../assets/google-play.svg';
import Banner1 from '../../assets/Banner-1.jpg';
import Banner2 from '../../assets/Banner-2.jpg';
import levels from '../../assets/Bloque-1.jpg';
import block2 from '../../assets/Bloque-2.gif';
import block3 from '../../assets/Bloque-3.gif';
import {deviceSelection} from '../../hooks/getDevice'

import './Home.scss';

const HomePage = () => {
    let deviceLink = deviceSelection()
    

    return (
        <div className="home-page">
            <header className="header-area">
                <div className="content">
                    <h1>¡Consigue recompensas espectaculares con <span>Getbeautyfull!</span></h1>
                    <p>Obtén premios increíbles a medida subes de nivel. ¡Aprovecha al máximo tus compras descargando la app de Getbeautyfull!</p>
                    <ul className="download-area">
                        <li>
                            <Link to='https://apps.apple.com/us/app/getbeautyfull2-0/id6450018005'>
                                <img src={appStore} alt="Download in App Store"/>
                            </Link>
                        </li>
                        <li>
                            <Link to='https://play.google.com/store/apps/details?id=com.siman.gbf_clientes'>
                                <img src={googlePlay} alt="Download in Google Play"/>
                            </Link>
                        </li>
                    </ul>
                </div>
                <img src={heroImage} alt='main-hero' className='main-image'/>
            </header>

            <div className='content-area'>
                <div className='media'>
                    <img src={Banner1} alt="Join this fam"/>
                    <img src={Banner2} alt="Discover points"/>
                </div>
                <ul className='condition-menu'>
                    <li>Condiciones de ofertas</li>
                    <li>Horarios de sucursales</li>
                </ul>

                <h3>¡Descubre todo lo que ofrece tu nueva APP!</h3>

                <div className='horizontal-card'>
                    <img src={levels} alt='Bloque 1'/>
                    <div className='info'>
                        <h4>Adéntrate en la belleza exclusiva.</h4>
                        <p>Únete a nuestro programa de lealtad y desbloquea un mundo de eventos VIP y servicios de belleza premium. Con Getbeautyfull, cada nivel te acerca a experiencias únicas. ¡Descarga la app hoy mismo!</p>
                        <Button href={deviceLink}>¡Únete ya!</Button>
                    </div>
                </div>

                <div className='horizontal-card reverse'>
                    <img src={block2} alt='Bloque 2'/>
                    <div className='info'>
                        <h4>Empieza tu Viaje de Belleza y Recompensas</h4>
                        <p>Entra en tu perfil personalizado, un vistazo a tu progreso y recompensas. Desde las promociones en banners hasta los productos recién llegados, todo está diseñado para llevarte a una belleza excepcional.</p>
                        <Button href={deviceLink}>¡Empieza tu viaje aquí!</Button>
                    </div>
                </div>

                <div className='horizontal-card'>
                    <img src={block3} alt='Bloque 3'/>
                    <div className='info'>
                        <h4>¡Descubre un sin fin de premios que puedes canjear!</h4>
                        <p>Sumérgete en una experiencia de belleza única a través de nuestra página de producto en la app. Desde artículos de cuidado personal hasta productos de lujo, nuestra amplia gama de opciones se adapta a tus necesidades. Canjea tus puntos y descubre el lujo en tus manos. ¡Presiona el botón de canje y comienza a disfrutar de productos excepcionales!</p>
                        <Button href={deviceLink}>Descarga la App</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;