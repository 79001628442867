import { useParams } from 'react-router-dom';
import { brandList } from "./brands";

import './BrandsPage.scss';

const BrandsPage = () => {
    const { country } = useParams();

    return (
        <div className="brands-page">
            <h1>Conoce nuestras <span>marcas</span></h1>

            <div className="brands-list">
                {brandList[country].map((brand, index) => (
                    <img src={brand} alt={`brand-${index}`}/>
                ))}
            </div>
        </div>
    );
};

export default BrandsPage;