

import Footer from './Blocks/Footer';
import MainMenu from './Blocks/Navbar';

import './Layout.scss';

const Layout = ({children}) => {
    return (
        <>
            <MainMenu/>
            {children}
            <Footer/>
        </>
    );
};

export default Layout;