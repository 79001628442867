import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { FaFacebook } from 'react-icons/fa';
import { RiInstagramFill } from "react-icons/ri";

import logo from '../../../assets/logo.svg';

import './Footer.scss';

const Footer = () => {
  const { country } = useParams();

  const region = country ?? 'sv';

  const IGByRegion = {
    sv: {
      ig: 'https://www.instagram.com/getbeautyfullsv/',
      fb: 'https://www.facebook.com/GetBeautyFullsv'
    },
    gt: {
      ig: 'https://www.instagram.com/getbeautyfullgt/',
      fb: 'https://www.facebook.com/GetBeautyFullgt'
    },
    cr: {
      ig: 'https://www.instagram.com/getbeautyfullcr/',
      fb: 'https://www.facebook.com/profile.php?id=100046315826168'
    },
    ni: {
      ig: 'https://www.instagram.com/getbeautyfullni/',
      fb: 'https://www.facebook.com/GetBeautyFullni'
    }
  }

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <div className="logo">
              <img src={logo} alt="Footer Logo" />
            </div>
          </Col>
          <Col xs={12} md={12}>
            <ul className="footer-menu">
              <li><Link to={`/${region}/marcas`}>Marcas Participantes</Link></li>
              <li><Link to={`/${region}/terminos-y-condiciones`}>Condiciones de ofertas</Link></li>
              <li><Link to={`/${region}/sucursales`}>Sucursales</Link></li>
              <li>
                Redes sociales
                <div className='social-media'>
                  <Link to={IGByRegion[region].fb}>
                    <FaFacebook/>
                  </Link>
                  <Link to={IGByRegion[region].ig}>
                    <RiInstagramFill/>
                  </Link>
                </div>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className="info">
              <div className='copyright'>
                Copyright &copy; {new Date().getFullYear()} Getbeautyfull. Reservados todos los derechos.
              </div>
              <div className='contact'>
                Almacenes Siman  S.A. de C.V. - NIT: 0614-170266-001-3<br/>
                Almacenes venta de diversos artículos - Paseo General Escalón, Centro Comercial Galerías, San Salvador.<br/>
                Llámanos 2298-3777 - contacto@siman.com
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
