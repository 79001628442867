const SucursalesCR = () => {
    return (
        <>
            <h3>UBICACIONES TIENDAS PARTICIPANTES</h3>
            <h4>COSTA RICA</h4>
            <ul>
                <li>
                    Siman Escazú<br/>
                    Centro Comercial Multiplaza Escazú Quinta etapa distrito 3 San Rafael Cantón dos, Escazú, San José.
                </li>
                <li>
                    Siman Curridabat<br/>
                    Centro Comercial “Multiplaza del Este” Distrito 1, Cantón 18 Curridabat - Provincia San José.
                </li>
                <li>
                    Siman Alajuela City Mall<br/>
                    City Mall, Provincia de Alajuela, Alajuela, Costa Rica.
                </li>
                <li>
                    MAC Curridabat<br/>
                    Centro Comercial “Multiplaza del Este” Distrito 1, Cantón 18 Curridabat - Provincia San José.
                </li>
                <li>
                    MAC Escazú<br/>
                    Centro Comercial Multiplaza Escazú Quinta etapa distrito 3 San Rafael Cantón dos, Escazú, San José.
                </li>
                <li>
                    MAC Heredia<br/>
                    Centro Comercial Paseo De Las Flores, Nivel Ii, Heredia, San Jose.
                </li>
            </ul>
        </>
    );
}

export default SucursalesCR;