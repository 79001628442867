import { useParams } from 'react-router-dom';

import PrivacySV from './countries/sv';
import PrivacyGT from './countries/gt';
import PrivacyCR from './countries/cr';
import PrivacyNI from './countries/ni';

import banner from '../../assets/terms-conditions-hero.png';
import './PrivacyPage.scss';

const PrivacyPage = () => {
    const { country } = useParams();

    const getContent = () => {
        switch(country){
            case 'gt':
                return <PrivacyGT/>;
            case 'cr':
                return <PrivacyCR/>;
            case 'ni':
                return <PrivacyNI/>;
            default:
                return <PrivacySV/>;
        }
    }
    return (
        <div className="privacy-page">
            <img src={banner} className='hero-banner' alt='Politica de privacidad'/>
            <div className='content'>
                {getContent()}
            </div>
        </div>
    );
}

export default PrivacyPage;