import { useParams } from 'react-router-dom';

import banner from '../../assets/sucursales-hero.png';

import GT from './countries/gt';
import CR from './countries/cr';
import NI from './countries/ni';
import SV from './countries/sv';

import './SucursalesPage.scss';

const SucursalesPage = () => {
    const { country } = useParams();

    const getContent = () => {
        switch(country){
            case 'gt':
                return <GT/>;
            case 'cr':
                return <CR/>;
            case 'ni':
                return <NI/>;
            default:
                return <SV/>;
        }
    }
    return (
        <div className="sucursales-page">
            <img src={banner} className='hero-banner' alt='sucursales banner'/>
            <div className='content'>
                {getContent()}
            </div>
        </div>
    );
}

export default SucursalesPage;