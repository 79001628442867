import { useParams } from 'react-router-dom';

import ConditionSV from './countries/sv';
import ConditionGT from './countries/gt';
import ConditionCR from './countries/cr';
import ConditionNI from './countries/ni';

import banner from '../../assets/terms-conditions-hero.png';
import './ConditionsPage.scss';

const ConditionsPage = () => {
    const { country } = useParams();

    const getContent = () => {
        switch(country){
            case 'gt':
                return <ConditionGT/>;
            case 'cr':
                return <ConditionCR/>;
            case 'ni':
                return <ConditionNI/>;
            default:
                return <ConditionSV/>;
        }
    }
    return (
        <div className="conditions-page">
            <img src={banner} className='hero-banner' alt='terminos y condiciones banner'/>
            <div className='content'>
                {getContent()}
            </div>
        </div>
    );
}

export default ConditionsPage;