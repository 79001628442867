const SucursalesNI = () => {
    return (
        <>
            <h3>UBICACIONES TIENDAS PARTICIPANTES</h3>
            <h4>NICARAGUA</h4>
            <ul>
                <li>
                    Siman Santo Domingo<br/>
                    Kilómetro 7 carretera a Masaya de la Rotonda Jean Paul Genie 100 mts al sur Centro comercial Galerías Santo Domingo, Nicaragua.
                </li>
                <li>
                    Siman Metrocentro<br/>
                    Centro comercial Metrocentro, San Jose, Nicaragua.
                </li>
                <li>
                    MAC Santo Domingo<br/>
                    Kilómetro 7 carretera a Masaya de la Rotonda Jean Paul Genie 100 mts al sur Centro comercial Galerías Santo Domingo, Nicaragua
                </li>
            </ul>
        </>
    );
}

export default SucursalesNI;