import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import {deviceSelection} from '../../../hooks/getDevice'


import logo from '../../../assets/logo.svg';

import './Navbar.scss';

const MainMenu = () => {
    let deviceLink = deviceSelection()
    const { country } = useParams();
    const logoURL = country ? `/${country}` : '/';
    return (
        <div className='main-menu'>
            <Link to={logoURL}>
                <img
                    src={logo}
                    className='logo'
                    alt="Get Beauty App Logo"
                />
            </Link>
            <ul className='menu-list'>
                <li>
                    <Button className='download-app'>
                        <Link to={deviceLink}>
                        Descarga la app
                        </Link>
                    </Button>
                </li>
            </ul>
        </div>
    );
};

export default MainMenu;