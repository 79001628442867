const ConditionCR = () => {
    return (
        <>
            <h3>TÉRMINOS Y CONDICIONES PROGRAMA DE LEALTAD-GETBEAUTYFULL</h3>
            <ol>
                <li>
                    Inscripción
                    <ul>
                        <li>Para inscribirse en el programa de lealtad, los clientes deben ser mayores de 18 años y residir en el país donde se encuentra la tienda en la que se inscriben.</li>
                        <li>La participación en el Programa es gratuita y requiere la inscripción en tiendas SIMAN, MAC, XCLAIM o a través de la aplicación oficial.</li>
                        <li>Clientes pueden inscribirse con documento de identidad oficial del país o carné de residencia.</li>
                    </ul>
                </li>
                <li>
                    ACUMULACIÓN DE PUNTOS
                    <ul>
                        <li>Los miembros del Programa acumulan puntos por cada compra de productos de cosméticos y fragancias en tiendas SIMAN, MAC o XCLAIM.</li>
                        <li>Clientes deben mostrar su identificación al momento de realizar una compra para la acumulación respectiva. Si no presenta su ID, no verá reflejado sus puntos.</li>
                        <li>Se asignarán los siguientes niveles de acumulación de puntos, según la compra realizada.</li>
                    </ul>
                    <p>
                        Beauty Lover: C0 - C174,999 colones.<br/>
                        Beauty Addict: C175,000 - C599,999 colones.<br/>
                        Beauty Holic: C600,000 a más colones.
                    </p>
                    <ul>
                        <li>Las acumulaciones de puntos por nivel son de la siguiente manera:</li>
                    </ul>
                    <p>
                        Beauty Lover: por cada C500, cliente acumula 1 punto.<br/>
                        Beauty Addict: por cada C500, cliente acumula 2 puntos.<br/>
                        Beauty Holic: por cada C500, cliente acumula 3 puntos.<br/>
                    </p>
                </li>
                <li>
                    CANJE DE PUNTOS
                    <ul>
                        <li>Los puntos acumulados pueden ser canjeados por productos de marcas seleccionadas disponibles en tiendas participantes, sujeto a disponibilidad.</li>
                        <li>Los clientes pueden canjear únicamente un producto de cada tipo.</li>
                        <li>Cliente puede redimir por premios físicos, servicios express o cashbacks.</li>
                    </ul>
                </li>
                <li>
                    VIGENCIA DE PUNTOS
                    <ul>
                        <li>Los puntos acumulados tienen una vigencia de 1 año a partir de la fecha de acumulación. Después de este periodo, los puntos expirarán y no podrán ser utilizados.</li>
                    </ul>
                </li>
                <li>
                    OFERTAS Y PROMOCIONES ESPECIALES
                    <ul>
                        <li>Los miembros del Programa pueden recibir ofertas y promociones exclusivas, las cuales estarán sujetas a disponibilidad y podrán variar de acuerdo con las políticas establecidas.</li>
                        <li>Colaboradores pueden hacer uso de todos los beneficios de APP GetBeautyFull.</li>
                    </ul>
                </li>
                <li>
                    CAMBIOS Y TERMINACIONES DEL PROGRAMA
                    <ul>
                        <li>GetBeautyfull se reserva el derecho de realizar cambios en términos y condiciones del Programa.</li>
                    </ul>
                </li>
                <li>
                    PRIVACIDAD Y PROTECCIÓN DE DATOS
                    <ul>
                        <li>GetBeautyfull se compromete a proteger la privacidad de los datos de los miembros del Programa y a utilizarlos conforme a las leyes y regulaciones de protección de datos aplicables.</li>
                    </ul>
                </li>
                <li>
                    Descripciones Generales
                    <ul>
                        <li>Estos términos y condiciones representan el acuerdo completo entre las partes y sustituyen cualquier acuerdo anterior. Cualquier modificación de estos términos deberá realizarse por escrito y ser aceptada por ambas partes.</li>
                    </ul>
                </li>
                <li>
                    Política de Privacidad
                    <p>
                        Conocemos la importancia de mantener su información confidencial, tanto para ustedes como para nosotros. Si en cualquier momento desea que borremos su información de nuestros registros, puede comunicarse con nosotros a XXX, comunicándonos sus nombres y apellidos, así como las razones por las que desea ser excluido de nuestros registros.
                        <br/><br/>
                        Según nuestros Términos Legales: GetBeautyFull se compromete a mantener la privacidad de toda la información proporcionada por un usuario del sitio de Internet y solamente revelarla si fuese requerida por una autoridad judicial competente.
                    </p>
                </li>
            </ol>
        </>
    );
}

export default ConditionCR;