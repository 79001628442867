import brand_NI1 from '../../../assets/brands/ni/01-Marca.svg';
import brand_NI2 from '../../../assets/brands/ni/02-Marca.svg';
import brand_NI3 from '../../../assets/brands/ni/03-Marca.svg';
import brand_NI4 from '../../../assets/brands/ni/04-Marca.svg';
import brand_NI5 from '../../../assets/brands/ni/05-Marca.svg';
import brand_NI6 from '../../../assets/brands/ni/06-Marca.svg';
import brand_NI7 from '../../../assets/brands/ni/07-Marca.svg';
import brand_NI8 from '../../../assets/brands/ni/08-Marca.svg';
import brand_NI9 from '../../../assets/brands/ni/09-Marca.svg';
import brand_NI10 from '../../../assets/brands/ni/10-Marca.svg';
import brand_NI11 from '../../../assets/brands/ni/11-Marca.svg';
import brand_NI12 from '../../../assets/brands/ni/12-Marca.svg';
import brand_NI13 from '../../../assets/brands/ni/13-Marca.svg';
import brand_NI14 from '../../../assets/brands/ni/14-Marca.svg';
import brand_NI15 from '../../../assets/brands/ni/15-Marca.svg';
import brand_NI16 from '../../../assets/brands/ni/16-Marca.svg';
import brand_NI17 from '../../../assets/brands/ni/17-Marca.svg';
import brand_NI18 from '../../../assets/brands/ni/18-Marca.svg';
import brand_NI19 from '../../../assets/brands/ni/19-Marca.svg';
import brand_NI20 from '../../../assets/brands/ni/20-Marca.svg';
import brand_NI21 from '../../../assets/brands/ni/21-Marca.svg';
import brand_NI22 from '../../../assets/brands/ni/22-Marca.svg';
import brand_NI23 from '../../../assets/brands/ni/23-Marca.svg';
import brand_NI24 from '../../../assets/brands/ni/24-Marca.svg';
import brand_NI25 from '../../../assets/brands/ni/25-Marca.svg';
import brand_NI26 from '../../../assets/brands/ni/26-Marca.svg';
import brand_NI27 from '../../../assets/brands/ni/27-Marca.svg';
import brand_NI28 from '../../../assets/brands/ni/28-Marca.svg';

export const NI_BRANDS = [
    brand_NI1, brand_NI2, brand_NI3,
    brand_NI4, brand_NI5, brand_NI6,
    brand_NI7, brand_NI8, brand_NI9,
    brand_NI10, brand_NI11, brand_NI12,
    brand_NI13, brand_NI14, brand_NI15,
    brand_NI16, brand_NI17, brand_NI18,
    brand_NI19, brand_NI20, brand_NI21,
    brand_NI22, brand_NI23, brand_NI24,
    brand_NI25, brand_NI26, brand_NI27,
    brand_NI28,
];
