export function detectarSistemaOperativo() {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return 'Android';
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
        return 'iOS';
    } else if (/Windows/i.test(userAgent)) {
        return 'Windows';
    }  else if (/Macintosh|Mac OS/i.test(userAgent)) {
        // Verificar si es Safari
        if (/Safari/i.test(userAgent)) {
            return 'macOS';
        } else {
            return 'Otro macOS';
        }
    } else if (/Linux/i.test(userAgent)) {
        return 'Linux';
    } else {
        return 'Desconocido';
    }
}

export function setDeviceLinkBySO(device) {
    if(device === 'Windows' || device === 'Android' || device === 'Linux'){
        return 'https://play.google.com/store/apps/details?id=com.siman.gbf_clientes'
    }
    else if(device === 'iOS' || device === 'macOS'){
        return 'https://apps.apple.com/us/app/getbeautyfull2-0/id6450018005'
    }
    else {
        return 'https://play.google.com/store/apps/details?id=com.siman.gbf_clientes'
    }
}

export function deviceSelection(){
    let device = detectarSistemaOperativo()
    let deviceLink = setDeviceLinkBySO(device)
    return deviceLink
}

