const SucursalesSV = () => {
    return (
        <>
            <h3>UBICACIONES TIENDAS PARTICIPANTES</h3>
            <h4>EL SALVADOR</h4>
            <ul>
                <li>
                    Siman Galerías<br/>
                    Centro Comercial Galerias, Paseo General Escalón #3737, Colonia Escalón, San Salvador, El Salvador.
                </li>
                <li>
                    Siman Metrocentro<br/>
                    39 Avenida norte Avenida los Sisimiles y avenida los andes Metrocentro 7ma. Etapa San Salvador.
                </li>
                <li>
                    Siman La Gran Vía<br/>
                    Centro Comercial La Gran Via, Carretera Panamericana Y Calle Chiltiupan, Antiguo Cuscatlan, La Libertad, El Salvador.
                </li>
                <li>
                    Siman Plaza Mundo<br/>
                    Centro Comercial Galerias, Paseo General Escalón #3737, Colonia Escalón, San Salvador, El Salvador.
                </li>
                <li>
                    Siman Santa Ana<br/>
                    Avenida Independencia Sur Centro comercial Metrocentro Local #101 Santa Ana
                </li>
                <li>
                    Siman San Miguel<br/>
                    Km 141 Centro Comercial Metrocentro Local 119 y 120 San Miguel.
                </li>
                <li>
                    MAC Galerías<br/>
                    Centro Comercial Galerias, Paseo General Escalón #3737, Colonia Escalón, San Salvador, El Salvador.
                </li>
                <li>
                    MAC Multiplaza<br/>
                    Centro Comercial Multiplaza, Carretera Panamericana #68, Antiguo Cuscatlán.
                </li>
                <li>
                    MAC La Gran Vía<br/>
                    Centro Comercial La Gran Via, Carretera Panamericana Y Calle Chiltiupan, Antiguo Cuscatlan, La Libertad, El Salvador.
                </li>
                <li>
                    XCLAIM Galerias<br/>
                    Centro Comercial Galerias, Paseo General Escalón #3737, Colonia Escalón, San Salvador, El Salvador.
                </li>
                <li>
                    XCLAIM Bambú<br/>
                    Centro Comercial Bambu City Center, Boulevard el Hipódromo Zona Rosa. San Salvador.
                </li>
            </ul>
        </>
    );
}

export default SucursalesSV;