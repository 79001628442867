import brand_SV from '../../../assets/brands/sv/Marca.svg';
import brand_SV1 from '../../../assets/brands/sv/Marca-1.svg';
import brand_SV2 from '../../../assets/brands/sv/Marca-2.svg';
import brand_SV3 from '../../../assets/brands/sv/Marca-3.svg';
import brand_SV4 from '../../../assets/brands/sv/Marca-4.svg';
import brand_SV5 from '../../../assets/brands/sv/Marca-5.svg';
import brand_SV6 from '../../../assets/brands/sv/Marca-6.svg';
import brand_SV7 from '../../../assets/brands/sv/Marca-7.svg';
import brand_SV8 from '../../../assets/brands/sv/Marca-8.svg';
import brand_SV9 from '../../../assets/brands/sv/Marca-9.svg';
import brand_SV10 from '../../../assets/brands/sv/Marca-10.svg';
import brand_SV11 from '../../../assets/brands/sv/Marca-11.svg';
import brand_SV12 from '../../../assets/brands/sv/Marca-12.svg';
import brand_SV13 from '../../../assets/brands/sv/Marca-13.svg';
import brand_SV14 from '../../../assets/brands/sv/Marca-14.svg';
import brand_SV15 from '../../../assets/brands/sv/Marca-15.svg';
import brand_SV16 from '../../../assets/brands/sv/Marca-16.svg';
import brand_SV17 from '../../../assets/brands/sv/Marca-17.svg';
import brand_SV18 from '../../../assets/brands/sv/Marca-18.svg';
import brand_SV19 from '../../../assets/brands/sv/Marca-19.svg';
import brand_SV20 from '../../../assets/brands/sv/Marca-20.svg';
import brand_SV21 from '../../../assets/brands/sv/Marca-21.svg';
import brand_SV22 from '../../../assets/brands/sv/Marca-22.svg';
import brand_SV23 from '../../../assets/brands/sv/Marca-23.svg';
import brand_SV24 from '../../../assets/brands/sv/Marca-24.svg';
import brand_SV25 from '../../../assets/brands/sv/Marca-25.svg';
import brand_SV26 from '../../../assets/brands/sv/Marca-26.svg';
import brand_SV27 from '../../../assets/brands/sv/Marca-27.svg';
import brand_SV28 from '../../../assets/brands/sv/Marca-28.svg';
import brand_SV29 from '../../../assets/brands/sv/Marca-29.svg';
import brand_SV30 from '../../../assets/brands/sv/Marca-30.svg';
import brand_SV31 from '../../../assets/brands/sv/Marca-31.svg';
import brand_SV32 from '../../../assets/brands/sv/Marca-32.svg';

export const SV_BRANDS = [
    brand_SV, brand_SV1, brand_SV2, brand_SV3, brand_SV4, 
    brand_SV5, brand_SV6, brand_SV7, brand_SV8, brand_SV9, 
    brand_SV10, brand_SV11, brand_SV12, brand_SV13, brand_SV14, 
    brand_SV15, brand_SV16, brand_SV17, brand_SV18, brand_SV19, 
    brand_SV20, brand_SV21, brand_SV22, brand_SV23, brand_SV24, 
    brand_SV25, brand_SV26, brand_SV27, brand_SV28, brand_SV29, 
    brand_SV30, brand_SV31, brand_SV32
]