import { useContext } from "react";
import { RegionContext } from '../../context/region';
import { useNavigate, Link } from "react-router-dom";

import SV from '../../assets/flags/SV.svg';
import GT from '../../assets/flags/GT.svg';
import CR from '../../assets/flags/CR.svg';
import NI from '../../assets/flags/NI.svg';

import './CountrySelectorPage.scss';

const CountrySelectorPage = () => {
    const navigate = useNavigate();
    const { region } = useContext(RegionContext);
    if (region) {
        navigate(`/${region}`);
        navigate(0);
    }

    return (
        <div className="country-selector">
            <h3>Elige tu pa&iacute;s</h3>

            <div className="selector">
                <Link to={'/sv'} className="flag-item">
                    <img src={SV} alt="SV-flag"/>
                    <span>El Salvador</span>
                </Link>
                <Link to={'/gt'} className="flag-item">
                    <img src={GT} alt="GT-flag"/>
                    <span>Guatemala</span>
                </Link>
                <Link to={'/cr'} className="flag-item">
                    <img src={CR} alt="CR-flag"/>
                    <span>Costa Rica</span>
                </Link>
                <Link to={'/ni'} className="flag-item">
                    <img src={NI} alt="NI-flag"/>
                    <span>Nicaragua</span>
                </Link>
            </div>
            {region}
        </div>
    );
}

export default CountrySelectorPage;