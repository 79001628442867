const SucursalesGT = () => {
    return (
        <>
            <h3>UBICACIONES TIENDAS PARTICIPANTES</h3>
            <h4>GUATEMALA</h4>
            <ul>
                <li>
                    Siman Oakland<br/>
                    Diagonal 6 1301 Zona 10 Centro Comercial Oakland Mall.
                </li>
                <li>
                    Siman Pradera Concepción<br/>
                    Centro Comercial Pradera Concepción km. 15.5 Carretera a El Salvador, Santa Catarina Pínula.
                </li>
                <li>
                    Siman Miraflores<br/>
                    21 Avenida 4-32 Zona 11 Centro Comercial Miraflores.
                </li>
                <li>
                    Siman Próceres<br/>
                    18 calle 2-21 Zona 10 Gran centro comercial Los Próceres.
                </li>
                <li>
                    MAC Oakland<br/>
                    Diagonal 6 1301 Zona 10 Centro Comercial Oakland Mall.
                </li>
                <li>
                    MAC Miraflores<br/>
                    21 Avenida 4-32 Zona 11 Centro Comercial Miraflores.
                </li>
                <li>
                    XCLAIM Oakland<br/>
                    Diagonal 6 1301 Zona 10 Centro Comercial Oakland Mall.
                </li>
                <li>
                    MAC Multiplaza<br/>
                    Centro Comercial Multiplaza, Carretera Panamericana #68, Antiguo Cuscatlán.
                </li>
                <li>
                    XCLAIM La Estación<br/>
                    Plaza la Estación, zona 10 Ciudad de Guatemala.
                </li>
                <li>
                    MAC Miraflores<br/>
                    21 Avenida 4-32 Zona 11 Centro Comercial Miraflores.
                </li>
                <li>
                    MAC Pradera Concepción<br/>
                    Centro Comercial Pradera Concepción km. 15.5 Carretera a El Salvador, Santa Catarina Pínula.
                </li>
                <li>
                    MAC La Estación<br/>
                    Plaza la Estación, zona 10 Ciudad de Guatemala.
                </li>
            </ul>
        </>
    );
}

export default SucursalesGT;